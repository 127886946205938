<template>
    <div>
        <v-expand-transition>
            <div v-show="open_headers">
                <div class="cell3"><!-- style="box-shadow: 0 0 6px rgba(0,0,0,0.12) inset">-->
                <!--<div>-->
                    <v-divider class="mb-2" />
                    <v-row align="center" no-gutters class="px-3">
                        <v-col>
                            <div class="paragraph">{{ $t('single_trad.Table.filters') }}</div>
                        </v-col>
                        <Button iconVal="mdi-close" iconSmall icon fab :styles="{marginRight: '2px', maxWidth: '24px', minWidth: '24px', maxHeight: '24px', minHeight: '24px'}" :click="() => open_headers = !open_headers"/>
                    </v-row>
                    <v-row no-gutters class="px-3">
                        <v-col cols="12">
                            <HeadersSelect v-model="fields" :items="allFields" @change="$emit('headersChanged')" />
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5 mb-2" />
                </div>
            </div>
        </v-expand-transition>

        <v-row class="flex-nowrap mx-0 mb-2 px-1 overflow-hidden" align="center" v-if="Object.keys(fields).length > 0">
            <v-col cols="auto" v-if="methods.find(e => e === 'destroy')" class="pl-2 pr-0 pb-2" style="min-width: 40px">
                <CheckBox
                        v-model="select_all"
                        activeColor="secondary"
                        :intermediate="selected.length > 0 && selected.length !== items.length"
                        @intermediate="select_all = true"
                        @input="selectAll()"
                />
            </v-col>
            <v-col
                    :key="i"
                    :style="header.type === 'boolean' ? {maxWidth: 50 + 'px'} : {}"
                    @keyup.enter="applyFilters()"
                    class="pa-2"
                    v-for="(header, key, i) in fields"
                    v-if="$vuetify.breakpoint.width >= 960 && header.type !== 'image' && header.type !== 'file'"
            >
                <v-row class="mx-0 flex-nowrap" align="center" justify="center">
                    <TextField
                            v-if="header.type === 'string' || header.type === 'integer' || header.type === 'float' || header.type === 'html' || header.type === 'email' || header.type === 'text' || header.type === 'color' || header.type === 'duration'"
                            v-model="filters[key]"
                            :label="fields[key].label"
                            @keyup="checkFilters()"
                            :readonly="fields[key].read_only"
                            :title="fields[key].label"
                    />
                    <TimePicker
                            v-else-if="header.type === 'datetime' || header.type === 'date'"
                            :outlined="true"
                            v-model="filters[key]"
                            :placeholder="fields[key].label"
                            :readonly="fields[key].read_only"
                            :title="fields[key].label"
                    />
                    <CheckBox
                            v-else-if="header.type === 'boolean'"
                            v-model="filters[key]"
                            @input="checkFilters()"
                            :labelTop="fields[key].label"
                            :readonly="fields[key].read_only"
                            :intermediate="typeof filters[key] === 'undefined'"
                            @intermediate="$delete(filters, key); checkFilters()"
                            :title="fields[key].label"
                    />
                    <Select
                            v-else-if="header.type === 'select'"
                            v-model="filters[key]"
                            :label="fields[key].label"
                            :items="header.choices"
                            @change="checkFilters()"
                            :clearable="true"
                            :readonly="fields[key].read_only"
                            :attach="false"
                            returnValue
                            :title="fields[key].label"
                    />
                    <Select
                            v-else-if="header.type === 'multiselect'"
                            v-model="filters[key]"
                            :label="fields[key].label"
                            :multiple="true"
                            :items="header.choices"
                            @change="checkFilters()"
                            :clearable="true"
                            :readonly="fields[key].read_only"
                            :attach="false"
                            returnValue
                            :title="fields[key].label"
                    />
                    <Select
                            v-else-if="header.type === 'choice'"
                            v-model="filters[key]"
                            :label="fields[key].label"
                            :items="header.choices"
                            @change="checkFilters()"
                            :clearable="true"
                            :readonly="fields[key].read_only"
                            :attach="false"
                            returnValue
                            itemVal="value"
                            :title="fields[key].label"
                    />
                    <Button
                            v-if="ordering.includes(key) && fields[key].type !== 'boolean'"
                            :styles="{minWidth: '20px', maxWidth: '20px', height: '20px'}"
                            icon
                            iconVal="mdi-arrow-up"
                            :classes="filters['ordering'] === key ? 'transition-rotate ml-1':'ml-1'"
                            iconSize="14"
                            :toolTip="$tc('single_trad.Table.sort_by', 1, {type: fields[key].label })"
                            :click="() => orderBy(key, i)"
                            :color="typeof filters['ordering'] === 'undefined' || !filters['ordering'].match(key) ? 'cell4':'primary'"
                    />
                </v-row>
            </v-col>
            <v-col cols="auto" v-if="$vuetify.breakpoint.width >= 960" class="pa-2">
                <Button
                        :color="validate_filters ? 'primary':''"
                        :disabled="!validate_filters"
                        :click="() => applyFilters()"
                        iconVal="mdi-check"
                        carre="carre"
                        :classes="'mr-2'"
                        flat
                />
                <Button
                        :color="clear_filters ? 'error':''"
                        :disabled="!clear_filters"
                        iconVal="mdi-close"
                        carre="carre"
                        :click="() => removeFilters()"
                        flat
                />
            </v-col>
        </v-row>

        <template v-if="$vuetify.breakpoint.width >= 960 && ready">
            <v-hover v-slot:default="{ hover }" v-for="(item, i) in items" :key="item.id" class="cursor-pointer">
                <v-row
                        @click.stop="item.__meta__.methods.includes('retrieve') ? $router.push('/dashboard/' + path + '/' + item.id) : ''"
                        :class="item._selected ? (i % 2 !== 0 ? 'secondary white--text': 'secondary darken-1 white--text') : (i % 2 !== 0 ? (hover ? 'cell3 darken-1':''): (hover ? 'cell3 darken-1':'cell3'))"
                        align="center"
                        class="transition-cubic flex-nowrap mx-0 px-1 overflow-hidden"
                >
                    <v-col cols="auto" v-if="methods.includes('destroy')" style="min-width: 40px"
                           class="pl-2 pr-0 pb-2">
                        <CheckBox
                                v-if="item.__meta__.methods.includes('destroy') && methods.includes('create') && !item.__meta__.locked"
                                v-model="items[i]['_selected']"
                                activeColor="white"
                                @input="(e) => {e ? selected.push(item) : selected.splice(selected.findIndex(e => e.id === item.id), 1)}"
                        />
                    </v-col>
                    <v-col
                            v-for="(field, key, j) in fields"
                            :key="j"
                            v-if="key !== '_selected' && key !== 'editable' && fields[key] && field.type !== 'image' && field.type !== 'file'"
                            class="px-2 text-truncate paragraph"
                            style="line-height: 44px; padding-top: 3px; padding-bottom: 3px"
                            :style="field.type === 'boolean' ? {maxWidth: 50 + 'px'}:{}"
                    >
                        <v-icon v-if="item.__meta__.locked && j === 0" color="warning" x-small class="float-left pr-1 py-4">mdi-lock</v-icon>
                        <div
                                v-if="fields[key].type === 'html'"
                                class="text-truncate"
                                :title="$options.filters.striphtml(item[key])"
                        >
                            {{ item[key] | striphtml }}
                        </div>

                        <div
                                v-else-if="fields[key].type === 'color'"
                                class="rounded-circle mx-auto"
                                :style="{backgroundColor: item[key]}"
                                style="width: 20px; height: 20px;"
                        ></div>

                        <div
                                v-else-if="fields[key].type === 'string' || fields[key].type === 'email' || fields[key].type === 'integer' || fields[key].type === 'float' || fields[key].type === 'duration'"
                                class="text-truncate"
                                :title="fields[key].label === 'location' ? $options.filters.firstCapital(item[key]) : item[key]"
                        >
                            {{ fields[key].label === 'location' ? $options.filters.firstCapital(item[key]) : item[key] }}
                        </div>

                        <div
                                v-else-if="fields[key].type === 'date' || fields[key].type === 'datetime'"
                                class="text-truncate"
                                :title="item[key] ? ( $options.filters.toLocalDate(item[key])) : ''"
                        >
                            {{ item[key] ? ( $options.filters.toLocalDate(item[key])) : '' }}
                        </div>

                        <div
                                v-else-if="fields[key].type === 'select'"
                                class="text-truncate"
                                v-html="fields[key].choices && item[key] ? fields[key].choices.find(e => e.id === item[key].id) ? fields[key].choices.find(e => e.id === item[key].id).display_name : `<span class='label--text' style='font-style: italic'>${item[key].display_name}</span>` : ''"
                        ></div>

                        <div
                                v-else-if="fields[key].type === 'choice'"
                                class="text-truncate"
                                :title="fields[key].choices && item[key] ? fields[key].choices.find(e => e.value === item[key]).display_name : ''"
                        >
                            {{ fields[key].choices && item[key] ? fields[key].choices.find(e => e.value === item[key]).display_name : '' }}
                        </div>

                        <v-row v-else-if="fields[key].type === 'multiselect'" class="flex-nowrap mx-0">
                            <div v-for="(v,k) in item[key]" :key="v.id" class="text-truncate" v-if="k < 1">
                                {{ v ? v.display_name : '' }}
                                <span class="mr-1">{{ k !== item[key].length - 1 ? '&mdash;':'' }}</span>
                            </div>
                            <div v-else-if="k === 1">{{ item[key].length - 1 }} more</div>
                        </v-row>

                        <div
                                v-else-if="fields[key].type === 'boolean'"
                                class="text-truncate text-center"
                        >
                            <v-icon size="18" color="cell4" style="position: relative; left: -4px">
                                {{ !item[key] ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked' }}
                            </v-icon>
                        </div>
                    </v-col>

                    <v-col cols="auto" style="min-width: 88px" align="right" class="px-2 py-0">
                        <v-row class="mx-0" justify="end">
                            <v-menu left offset-y rounded="lg">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" :dark="item._selected" style="width: 32px; height: 32px; min-width: 32px !important; min-height: 32px !important;" class="ml-2">
                                        <v-icon size="20">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item link @click="pevents.notify('fast-edit', items[i])" v-if="item.__meta__.methods.includes('update')">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <div class="caption-1 pr-2">{{ $t('single_trad.Table.fast_edition') }}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item link @click="pevents.notify('fast-create', items[i])" v-if="methods.includes('create') && !item.__meta__.locked">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon small>mdi-content-copy</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <div class="caption-1 pr-2">{{ $t('single_trad.Table.clone') }}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item link @click="pevents.notify('delete-item-item', item)" v-if="item.__meta__.methods.includes('destroy')">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <div class="caption-1 pr-2">{{ $t('single_trad.Table.delete') }}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                    </v-col>
                </v-row>
            </v-hover>
        </template>

        <template v-else-if="$vuetify.breakpoint.width < 960 && ready">
            <v-expansion-panels accordion flat tile>
                <v-expansion-panel
                        v-for="(item,i) in items"
                        :key="item.id"
                        :class="i % 2 !== 0 ? 'cell': 'cell2'"
                >
                    <v-expansion-panel-header class="py-1 px-4">
                        <v-row align="center" class="flex-nowrap">
                            <v-col cols="auto" class="pl-2 pr-0 pt-2 pb-1" v-if="methods.includes('destroy')">
                                <CheckBox
                                        v-model="select_all"
                                        activeColor="secondary"
                                        :intermediate="selected.length > 0 && !select_all"
                                        @input="selectAll()"
                                />
                            </v-col>
                            <v-col
                                    class="font-weight-bold label--text"
                                    style="max-width: 300px; win-width: 100px"
                            >
                                <div class="paragraph">{{ fields['display_name'].label }}</div>
                            </v-col>
                            <v-col cols="auto" v-if="item.__meta__.locked" class="pa-0">
                                <v-icon color="warning" x-small>mdi-lock</v-icon>
                            </v-col>
                            <v-col>
                                <div class="paragraph">{{ items[i].display_name }}</div>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row
                                v-for="(field, key, i) in fields"
                                :key="i"
                                v-if="key !== '_selected' && key !== 'display_name' && key !== 'editable'"
                        >
                            <v-col style="max-width: 52px"></v-col>
                            <v-col
                                    class="font-weight-bold label--text"
                                    style="max-width: 300px"
                            >
                                <div class="paragraph">{{ fields[key].label }}</div>
                            </v-col>
                            <v-col class="paragraph">
                                <div v-if="fields[key].type === 'html'" class="text-truncate">
                                    {{ item[key] | striphtml }}
                                </div>
                                <div v-if="fields[key].type === 'string'">{{ item[key] }}</div>
                                <div
                                        v-if="fields[key].type === 'date' || fields[key].type === 'datetime'"
                                >
                                    {{ item[key] ? ( $options.filters.toLocalDate(item[key])) : '' }}
                                </div>
                                <div
                                        v-else-if="fields[key].type === 'select'"
                                        class="text-truncate"
                                        v-html="fields[key].choices && item[key] ? fields[key].choices.find(e => e.id === item[key].id) ? fields[key].choices.find(e => e.id === item[key].id).display_name : `<span class='label--text' style='font-style: italic'>${item[key].display_name}</span>` : ''"
                                ></div>
                                <div v-else-if="fields[key].type === 'choice'" class="text-truncate">
                                    {{ fields[key].choices && item[key] ? fields[key].choices.find(e => e.value === item[key]).display_name : '' }}
                                </div>
                                <v-row v-else-if="fields[key].type === 'multiselect'" class="text-truncate mx-0">
                                    <div v-for="(v,j) in item[key]" :key="v.id">
                                        {{ v ? v.display_name : '' }}
                                        <span class="mr-1">{{ j !== item[key].length - 1 ? '&mdash;':'' }}</span>
                                    </div>
                                </v-row>
                                <div v-else-if="fields[key].type === 'boolean'">
                                    <v-icon>
                                        {{ !item[key] ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked' }}
                                    </v-icon>
                                </div>
                            </v-col>
                            <v-col style="max-width: 24px"></v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col style="max-width: 52px"></v-col>
                            <v-col class="font-weight-bold label--text sub-title-2" style="max-width: 300px">
                                <div class="paragraph">{{ $t('single_trad.Table.actions') }}</div>
                            </v-col>
                            <v-col>
                                <Button
                                        v-if="item.__meta__.methods.includes('retrieve')"
                                        :click="() => { $router.push('/dashboard/' + path + '/' + item.id) }"
                                        iconVal="mdi-eye"
                                        :toolTip="$t('global.action.view_all')"
                                        color="celldark"
                                        dark
                                        carre
                                />
                                <Button
                                        v-if="item.__meta__.methods.includes('update')"
                                        :click="() => pevents.notify('fast-edit', items[i])"
                                        iconVal="mdi-pencil"
                                        carre
                                        :toolTip="$t('single_trad.Dialog.fast_edit')"
                                        color="primary"
                                        classes="ml-2"
                                />
                                <Button
                                        v-if="methods.includes('create')"
                                        :click="() => pevents.notify('fast-create', items[i])"
                                        iconVal="mdi-content-copy"
                                        carre
                                        :toolTip="$t('single_trad.Table.clone')"
                                        color="secondary"
                                        classes="ml-2"
                                />
                                <Button
                                        v-if="item.__meta__.methods.includes('destroy')"
                                        :click="() => pevents.notify('delete-item-item', item)"
                                        iconVal="mdi-delete"
                                        carre
                                        :toolTip="$t('global.action.delete')"
                                        color="error"
                                        classes="ml-2"
                                />
                            </v-col>
                            <v-col style="max-width: 24px"></v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <v-divider class="mx-4 mt-2" v-if="totalItems > 1"/>

        <v-row class="mx-0 paragraph pt-1" align="center" v-if="totalItems !== 0 && ready">
            <v-col cols="auto" v-if="totalItems > 5" class="pr-0" style="max-width: 130px">
                <Select
                        v-model="items_per_page"
                        :items="[{val: 5, label: 5}, {val: 10, label: 10}, {val: 20, label: 20}, {val: 0, label: $t('single_trad.Table.all')}]"
                        itemText="label"
                        itemVal="val"
                        :label="$t('single_trad.Table.items_p_p')"
                        @change="setItemsPerPage()"
                        :attach="false"
                />
            </v-col>
            <v-col cols="auto" :class="totalItems > default_items_per_page ? 'pl-2':''">
                <div>{{ totalItems > default_items_per_page ? $t('single_trad.Table.of') : '' }} {{ totalItems }} {{
                    $tc('single_trad.Table.entries', totalItems) }}
                </div>
            </v-col>
            <v-spacer/>
            <v-col
                    v-if="pagination() > 1 && items_per_page !== 0"
                    cols="auto"
                    :class="totalItems > default_items_per_page ? 'pl-0':''"
            >
                <v-menu v-if="pagination() > 1" offset-y open-on-hover open-on-click rounded="lg">
                    <template v-slot:activator="{ on }">
                            <div v-on="on" class="py-1 transition-cubic cursor-pointer">{{ page }} {{ $tc('single_trad.Table.of', 1) }} {{ pagination() }}</div>
                    </template>
                    <v-list class="py-0">
                        <v-hover v-slot:default="{ hover }" v-for="n in pagination()" :key="n">
                            <div @click="applyPage(n)" class="text-center py-1 cursor-pointer" :class="[hover ? n === page ? 'primary': 'cell2' : n === page ? 'primary' : '']">
                                {{ n }}
                            </div>
                        </v-hover>
                    </v-list>
                </v-menu>
                <div v-else>{{ page }} {{ $tc('single_trad.Table.of', 1) }} {{ pagination() }}</div>
            </v-col>
            <v-col cols="auto" :class="totalItems > default_items_per_page ? 'pl-0':''" v-if="totalItems > items_per_page && items_per_page !== 0">
                <Button
                        v-if="page > 1"
                        :click="() => page - 1 < 1 ? '' : setPage(true)"
                        iconVal="mdi-chevron-left"
                        carre
                        iconSize="20"
                        color="cell5"
                        :classes="page < pagination() ? 'mr-2':''"
                />
                <Button
                        v-if="page !== pagination()"
                        :click="() => page + 1 > pagination() ? '': setPage()"
                        iconVal="mdi-chevron-right"
                        carre
                        iconSize="20"
                        color="cell5"
                />
            </v-col>
        </v-row>

        <div class="pa-8 text-center" v-else-if="!ready">
            <v-progress-circular indeterminate size="40" color="primary"/>
        </div>

        <v-row v-if="ready && totalItems === 0" dense justify="center" class="py-4">
            <v-col cols="auto" class="text-center">
                <v-icon class="mb-4" size="30">mdi-block-helper</v-icon>
                <div class="paragraph">{{ $tc('single_trad.Table.no_data', 1) }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "Table",
        props: ['actions', 'fields', 'allFields', 'ordering', 'path', 'pevents', 'routeChildren', 'methods'],
        components: {
            Button: () => import('@/components/ui/Button.vue'),
            Actions: () => import('@/components/Actions.vue'),
            TextField: () => import('@/components/fields/TextField.vue'),
            CheckBox: () => import('@/components/fields/Checkbox.vue'),
            Select: () => import('@/components/fields/Select.vue'),
            HeadersSelect: () => import('@/components/ui/HeadersSelect.vue'),
            Dialog: () => import('@/components/ui/Dialog.vue'),
            FieldGroup: () => import('@/components/FieldGroup.vue'),
            ColorField: () => import('@/components/fields/ColorField.vue'),
            TimePicker: () => import('@/components/fields/TimePicker.vue')
        },
        data() {
            return {
                selected: [],
                filters: {},
                items: [],
                select_all: undefined,
                intermediate: false,
                validate_filters: false,
                clear_filters: false,
                ready: false,
                totalItems: 0,
                page: 1,
                default_items_per_page: 10,
                items_per_page: 10,
                open_headers: false,
                item2delete: null,
                item2edit: {},
                loading: false,
                item_empty: true,
                creation_ok: false
            }
        },
        watch: {
            "$route"() {
                this.setFilters()
                this.initFilters()
                this.checkFilters()
                this.getItems()
            },
            selected(val){
                this.select_all = val.length > 0 && val.length === this.items.length
                this.pevents.notify('selection', this.selected)
            }
        },
        methods: {
            computeLink(endpoint) {
                let res = []

                if (endpoint) {
                    for (let i of endpoint.split('/')) {
                        if (i.match(':')) {
                            let vat = i.replace(':', '')
                            res.push(this.$route.params[vat])
                        } else {
                            res.push(i)
                        }
                    }
                }

                return '/dashboard/' + res.join('/')
            },
            applyPage(n) {
                if(n !== this.page){
                    this.page = n
                    this.filters['page'] = this.page
                    this.$router.push({query: this.filters}).catch(() => {})
                }
            },
            applyFilters() {
                if (this.$route.query['page']) {
                    this.page = 1
                    this.filters['page'] = 1
                }

                this.$router.push({query: this.filters}).catch(() => {})
            },
            checkFilters() {
                let count = 0
                let clear = 0

                for (let i in this.filters) {
                    if (i !== 'page_size' && i !== 'page' && i !== 'ordering' && i !== 'query_filter') {
                        if (isNaN(Number.parseInt(this.$route.query[i]))) {
                            if(typeof this.filters[i] === 'boolean'){
                                if (typeof this.$route.query[i] !== 'undefined'){
                                    if(this.filters[i] !== (this.$route.query[i] === 'true')) {
                                        count++
                                    }
                                    else {
                                        this.clear_filters = true
                                    }
                                }
                                else {
                                    count++
                                }
                            }
                            else {
                                if (typeof this.filters[i] !== 'undefined' && this.filters[i] !== this.$route.query[i]) {
                                    count++
                                } else {
                                    this.clear_filters = true
                                }
                            }
                        } else {
                            if (typeof this.filters[i] !== 'undefined' && this.filters[i] !== Number.parseInt(this.$route.query[i])) {
                                count++

                                if(Array.isArray(this.$route.query[i])){
                                    this.$route.query[i].forEach((e, j) => {
                                        this.filters[i][j] = parseInt(e)
                                    })
                                }
                            } else {
                                this.clear_filters = true
                            }
                        }

                        clear++
                    }
                }

                for (let i in this.$route.query) {
                    if (i !== 'page_size' && i !== 'page' && i !== 'ordering' && i !== 'query_filter') {
                        clear++
                    }
                }

                this.validate_filters = count !== 0;
                this.clear_filters = clear !== 0;
            },
            deleteItem() {
                this.loading = true
                this.$wsc.deleteItem(this.path, this.item2delete.id, success => {
                    let index = this.items.findIndex(e => e.id === parseInt(success.id))

                    this.items.splice(index, 1)
                    this.totalItems = this.items.length
                    this.confirm_dialog = false
                    this.loading = false
                }, fail => {
                    this.loading = false
                })
            },
            getItems() {
                this.ready = false

                this.$wsc.getList(this.path, this.filters, success => {
                    if (this.filters['page_size'] === 0) {
                        this.items = success
                        this.totalItems = success.length
                    } else {
                        this.items = success.results
                        this.totalItems = success.count
                    }

                    this.ready = true
                    this.$emit('ready')
                }, fail => {
                    this.ready = true
                })
            },
            initFilters() {
                for (let key in this.$route.query) {
                    if (key !== 'page' && key !== 'page_size' && key !== 'ordering' && key !== 'query_filter') {
/*                        if (this.fields[key].type === 'multiselect' || this.fields[key].type === 'select') {
                            this.$set(this.filters, key, {})

                            if (Array.isArray(this.$route.query[key])) {
                                for (let j of this.$route.query[key]) {
                                    this.$set(this.filters[key], Number.parseInt(j), true)
                                }
                            } else {
                                this.$set(this.filters, key, Number.parseInt(this.$route.query[key]))
                            }
                        } else {*/
                            if(this.$route.query[key] == 'false' || this.$route.query[key] == 'true'){
                                this.$set(this.filters, key, this.$route.query[key] == 'true')
                            }
                            else {
                                this.$set(this.filters, key, this.$route.query[key])
                            }
                        //}
                    }
                }
            },
            orderBy(key) {
                if (typeof this.filters['ordering'] !== 'undefined') {
                    if (this.filters['ordering'] === key) {
                        this.$set(this.filters, 'ordering', '-' + key)

                    } else if (this.filters['ordering'] === '-' + key) {
                        this.$delete(this.filters, 'ordering')
                    }
                } else {
                    this.$set(this.filters, 'ordering', key)
                }

                this.applyFilters()
            },
            pagination() {
                if ((this.totalItems / this.items_per_page) % 1 !== 0) {
                    return Math.trunc(this.totalItems / this.items_per_page) + 1
                } else {
                    return this.totalItems / this.items_per_page
                }
            },
            removeFilters() {
                for (let i in this.filters) {
                    delete this.filters[i]
                    this.validate_filters = false
                    this.clear_filters = false
                }

                this.applyFilters()
            },
            selectAll() {
                if(this.ready){
                    if (this.selected.length === this.items.length) {
                        this.selected = []

                        for (let i = 0; i < this.items.length; i++) {
                            this.$set(this.items[i], '_selected', false)
                        }
                    } else {
                        let res = []

                        for (let i = 0; i < this.items.length; i++) {
                            if(this.methods.includes('create') && this.items[i].__meta__.methods.includes('destroy') && !this.items[i].__meta__.locked) {
                                res.push(this.items[i])
                                this.$set(this.items[i], '_selected', true)
                            }
                        }

                        this.selected = [...res]
                    }
                }
            },
            setFilters() {
                this.filters = {...this.$route.query}

                this.items_per_page = this.$route.query['page_size'] ? parseInt(this.$route.query['page_size']) : 10
                this.page = this.$route.query['page'] ? parseInt(this.$route.query['page']) : 1

                this.filters['page_size'] = this.items_per_page
                this.filters['page'] = this.page
            },
            setItemsPerPage() {
                this.filters['page_size'] = this.items_per_page.val
                this.filters['page'] = 1

                this.$router.push({query: this.filters}).catch(() => {
                })
            },
            setPage(val) {
                if(val){
                    this.page--
                }
                else {
                    this.page++
                }

                this.filters['page'] = this.page
                this.$router.push({query: this.filters}).catch(() => {})
            },
            setUpHooks(){
                this.pevents.wait('headers', () => {
                    this.open_headers = !this.open_headers
                })

                this.pevents.wait('new-item', (item) => {
                    this.items.unshift(item)
                    this.totalItems++
                })

                this.pevents.wait('delete-items', success => {
                    success.succeded.forEach(e => {
                        let index = this.items.findIndex(l => l.id === e.id)
                        this.items.splice(index, 1)
                        this.totalItems = this.items.length

                        let index2 = this.selected.findIndex(l => l.id === e.id)
                        this.selected.splice(index2, 1)
                    })
                })

                this.pevents.wait('delete-item', success => {
                    let index = this.items.findIndex(e => e.id === parseInt(success.id))

                    this.items.splice(index, 1)
                    this.totalItems = this.items.length
                })

                this.pevents.wait('search', val => {
                    this.filters['query_filter'] = val
                    this.applyFilters()
                })

                this.pevents.wait('search-clear', () => {
                    this.$delete(this.filters, 'query_filter')
                    this.applyFilters()
                })
            }
        },
        created() {
            this.setUpHooks()
            this.setFilters()
            this.initFilters()
            this.checkFilters()
            this.getItems()
        },
    }
</script>

<style scoped>

</style>
